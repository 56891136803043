import { useEffect, useState } from "react";
import { useLogout } from "../hooks/useLogout";
import NavBar from './NavBar';
import './css/Styles.css'



import jsPDF from "jspdf";
import img from "./image";

import { useNavigate } from "react-router-dom";
import { GreenButton } from "../components/buttonStyles";
import { DateRangePicker } from 'rsuite';
import { downloadExcel } from "react-export-table-to-excel";
import amiriFont from "../assets/amiriF";
import { isWithinInterval, startOfDay } from 'date-fns';
import WasteMap from "./WasteMap";
import useAuth from "../context/authContext";
import * as XLSX from 'xlsx';

const WasteList = () => {
    const { user } = useAuth()

    const [wastes, setWastes] = useState([])
    const [resetMap, setResetMap] = useState(false)

    const { logout } = useLogout()


    const [originalWastes, setOriginalWastes] = useState([]);
    const [sDate, setsDate] = useState(null);
    const [selectedCoordinates, setSelectedCoordinates] = useState([]);

    const handleClick = () => {
        logout()
    }

    const [originalWastesCoordinates, setOriginalWastesCoordinates] = useState([]);

    const [selectedMarkerData, setSelectedMarkerData] = useState(null);

    // Function to update selected marker data
    const handleMarkerSelect = (data) => {
        setSelectedMarkerData(data);
    };

    useEffect(() => {
        if (selectedMarkerData) {
          // Display data related to the selected marker
          // Ensure it complements, not replaces, existing table data
        }
      }, [selectedMarkerData]);

    
    useEffect(() => {
        const fetchWastes = async () => {
            try {
                const response = await fetch('https://submit.enssol.tech/api/v1/wastes/');
                if (!response.ok) throw new Error('Failed to fetch');
                const json = await response.json();
                setWastes(json.wastes);
                setOriginalWastes(json.wastes);
                // Extract and set coordinates for all wastes here to avoid duplication
                    const coordinates = json.wastes.map(waste => {
                    let normalizedCoords = waste.coords.toString().replace(/[\[\]]/g, '');
                    const coordinateArray = normalizedCoords.split(/\s*,\s*/);
                    const lat = parseFloat(coordinateArray[0]);
                    const lng = parseFloat(coordinateArray[1]);
                    return { lat, lng };
                });
                setOriginalWastesCoordinates(coordinates);

            } catch (error) {
                console.error("Error fetching wastes:", error);
                // Handle error (e.g., set an error state, show a message to the user, etc.)
            }
        };
        fetchWastes();
    }, []);
    



    const handleSelect = (date) => {
        if (!date || date.length === 0) {
            // Use the original data as the current filtered data
            setWastes(originalWastes);

        } else {
            setsDate(date)
            const selection0 = startOfDay(new Date(date[0]));
            const selection1 = startOfDay(new Date(date[1]));
     

            const filtered = originalWastes.filter((waste) => {
                const wasteDate = startOfDay(new Date(waste.date));
   
               
                // Compare dates with time taken into account
                return isWithinInterval(wasteDate, { start: selection0, end: selection1 });
            });
       
            // Store the filtered data in the setWastes state
            setWastes(filtered);
        }
        setCurrentPage(1);
    };

    const [searchDriver, setsearchDriver] = useState('');
    const [searchMaterial, setsearchMaterial] = useState('');
    const [searchVehicle, setsearchVehicle] = useState('');
    const [searchType, setsearchType] = useState('');
    const [searchOrganization, setsearchOrganization] = useState('');
    const [searchConstant, setsearchConstant] = useState('')
    const [searchLocation, setsearchLocation] = useState('')
    const [searchPass, setsearchPass] = useState('')

    function removeDecimalsAndConvertToInteger(inputString) {
        // Remove any periods (.) or commas (,)
        const cleanedString = inputString.replace(/[.,]/g, '');
    
        // Convert the cleaned string to an integer
        const integerNumber = parseInt(cleanedString, 10);
    
        return integerNumber;
    }


    function parseDateStringToDate(dateString) {
        const [year, month, day] = dateString.split('-').map(Number);
        const date = new Date(year, month - 1, day); // Note: Month is 0-based in JavaScript Date objects
        return date;
    }
    

    const header = ['A/A', 'Blockchain ID', 'Ημέρα', 'Ώρα', 'Όχημα', 'Οδηγός', 'Θέση Φόρτωσης', 'Χώρος Απόρριψης', 'Θέση GPS', 'Βάρος(kg)', 'Υλικό', 'Φορέας', 'Παρατηρήσεις']
    let index = 0;
    const body = wastes.map(({ _id, adminId, co, __v, ...rest }) => {

        return [index += 1, rest.hash, parseDateStringToDate(rest.date), rest.time, rest.vehicle, rest.driver, rest.type, rest.location, rest.coords, removeDecimalsAndConvertToInteger(rest.kg), rest.material, rest.organisation, rest.pass]
    })
    

    function handleDownloadExcel() {

        // Creating a new workbook
        const wb = XLSX.utils.book_new();

        // Adding header to worksheet
        const ws_data = [header, ...body];

        const ws = XLSX.utils.aoa_to_sheet(ws_data);

        // Append worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, "Ροές Απορριμμάτων");

        // Write workbook and trigger download
        XLSX.writeFile(wb, "Ροές Απορριμμάτων.xlsx");
    }

    const navigate = useNavigate();

    const createAndDownloadWastePDF = () => {

        const header = ['A/A', 'Blockchain ID', 'Ημέρα', 'Ώρα', 'Όχημα', 'Οδηγός', 'Θέση Φόρτωσης', 'Χώρος Απόρριψης', 'Θέση GPS', 'Βάρος(kg)', 'Υλικό', 'Φορέας', 'Παρατηρήσεις']
        let index = 0;
        const body = wastes.map(({ _id, adminId, co, __v, ...rest }) => {

            return [index += 1, rest.hash, rest.date, rest.time, rest.vehicle, rest.driver, rest.type, rest.location, rest.coords, rest.kg, rest.material, rest.organisation, rest.pass]
        })

        const doc = new jsPDF({
            orientation: 'landscape'
        }); // Set A4 paper size in portrait mode (default)

        doc.addImage(
            img,
            'PNG',
            14, 10, 50, 35
        );
        doc.addFileToVFS('../assets/Arimo-Regular.ttf', amiriFont); // Replace 'YourGreekFont.ttf' with the actual filename
        doc.addFont('../assets/Arimo-Regular.ttf', 'Amiri', 'normal');
        doc.setFont("Amiri");

        const date = new Date().toLocaleDateString();
        const newText = 'Εκδόθηκε: ' + date

        if (sDate) {
            const selection0 = startOfDay(new Date(sDate[0])).toLocaleDateString();
            const selection1 = startOfDay(new Date(sDate[1])).toLocaleDateString();
            const select1 = 'Από: ' + selection0
            const select2 = 'Έως: ' + selection1
            doc.text(170, 55, select1); // Greek text
            doc.text(170, 63, select2); // Greek text
        }

        // Add Greek text
        doc.text(170, 35, 'Αναφορά Ροών Απορριμάτων'); // Greek text
        doc.text(170, 45, newText); // Greek text
        const text = 'Αναφορά Ροών Απορριμάτων';
        const fontSize = 6; // Adjust the font size as needed
        const textWidth = doc.getStringUnitWidth(text) * fontSize;

        // Calculate the position for the underline
        const underlineY = 35; // Adjust the Y-coordinate as needed

        // Draw the underline
        doc.setLineWidth(0.05); // Adjust the line width as needed
        doc.line(170, underlineY, 165 + textWidth, underlineY);
        const options = {
            // theme: 'grid',
            margin: { top: 40 }, // Adjust top margin as needed
            startY: 70, // Adjust starting Y-coordinate as needed
            tableWidth: 'auto',
            headStyles: {
                font: 'Amiri',
                fontSize: 3, // Adjust font size for headers
                fillColor: [33, 93, 15], // Specify the background color in RGB format (blue)rgb(33, 93, 15)
                textColor: [255, 255, 255], // Specify the text color (white)
            },
            bodyStyles: {
                fontSize: 4, // Adjust font size for data
                font: 'Amiri'
            },
        };


        doc.autoTable(header, body, options);

        const footerText = 'Πλατφόρμα Ροής Απορριμάτων Τεχνολογίας Blockchain - https://blockchain.enssol.tech \n © 2023 ENSSOL A.E. All rights reserved - central@enssol.gr';
        const pageWidth = doc.internal.pageSize.width; // Get the width of the page
        const pageHeight = doc.internal.pageSize.height; // Get the height of the page
        const fontSize1 = 6;

        // Add content to the PDF document with the specified font size
        doc.setFontSize(fontSize1);

        // Position the footer text at the bottom of the page
        doc.text(footerText, pageWidth / 2, pageHeight - 10, 'center');

        doc.save('report.pdf');

    }


    const filterData = wastes.filter((waste) => {
        const conditions = [];


        if (searchVehicle) {

            conditions.push(waste.vehicle.toLowerCase().includes(searchVehicle.toLowerCase()));
        }

        if (searchDriver) {

            conditions.push(waste.driver.toLowerCase().includes(searchDriver.toLowerCase()));
        }

        if (searchType) {
            conditions.push(waste.type.toLowerCase().includes(searchType.toLowerCase()));
        }

        if (searchLocation && searchLocation.trim() !== "") {
            conditions.push(waste.location?.toLowerCase().includes(searchLocation.toLowerCase()));
        }
        if (searchMaterial) {
            conditions.push(waste.material.toLowerCase().includes(searchMaterial.toLowerCase()));
        }

        if (searchOrganization) {
            conditions.push(waste.organisation.toLowerCase().includes(searchOrganization.toLowerCase()));
        }

        if (searchPass) {
            conditions.push(waste.pass.toLowerCase().includes(searchPass.toLowerCase()));
        }
        //console.log(conditions.every((condition) => condition))
        return conditions.every((condition) => condition);
    });
   

    useEffect(() => {
     
   
        const coordinates = filterData.map(data => {
  
            let normalizedCoords = data.coords.toString().replace(/[\[\]]/g, '');
            // Split the normalized string by comma, potentially surrounded by spaces
            const coordinateArray = normalizedCoords.split(/\s*,\s*/);

            // Extract the lat and lng values
            const lat = parseFloat(coordinateArray[0]);
            const lng = parseFloat(coordinateArray[1]);

            return { lat, lng }

        })

        setSelectedCoordinates(coordinates)
 
    }, [searchVehicle, searchDriver, searchType, searchLocation, searchMaterial, searchOrganization, searchPass, wastes]);

    // Default coordinates
    const [selectCo, setselectCo] = useState(null)

    const handleCoordinatesChange = (newCoordinates) => {

        let normalizedCoords = newCoordinates.toString().replace(/[\[\]]/g, '');
        // Split the normalized string by comma, potentially surrounded by spaces
        const coordinateArray = normalizedCoords.split(/\s*,\s*/);

        // Extract the lat and lng values
        const lat = parseFloat(coordinateArray[0]);
        const lng = parseFloat(coordinateArray[1]);


        setselectCo({ lat, lng });


    }
    const [selectedItem, setSelectedItem] = useState(null);

    // Function to update the selected item
    const handleSelectItem = (item) => {
        setSelectedItem(item);
    };

    function areCoordinatesEqual(coord1, coord2) {
        if (!coord1 || !coord2) {
            return false;
        }
    
        // Function to extract latitude and longitude from a coordinate
        const extractLatLng = (coord) => {
            if (Array.isArray(coord)) {
                // If coordinate is an array
                return coord.map(Number);
            } else if (coord && typeof coord === 'object') {
                // If coordinate is an object
                return [coord.lat, coord.lng].map(Number);
            } else {
                // If coordinate is a string or other format
                let normalizedCoord = coord.toString().replace(/[\[\]]/g, '');
                return normalizedCoord.split(/\s*,\s*/).map(Number);
            }
        };
    
        const [lat1, lng1] = extractLatLng(coord1);
        const [lat2, lng2] = extractLatLng(coord2);
    
        return lat1 === lat2 && lng1 === lng2;
    }

    const [mapKey, setMapKey] = useState(0);

    const handleResetMap = () => {
        setselectCo(null);
        setSelectedCoordinates([]); // Temporarily set to an empty array
        setTimeout(() => setSelectedCoordinates(originalWastesCoordinates), 0);
        setMapKey(prevKey => prevKey + 1); // Increment key to force re-render
    };


    useEffect(() => {
        if (resetMap) {
            // Additional logic if needed when resetMap is true
            setResetMap(false); // Set resetMap back to false
        }
    }, [resetMap]);


    function onMapReset() {
        setResetMap(false)
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filterData.slice(indexOfFirstItem, indexOfLastItem);


    const handleMapMarkerClick = (coordinate) => {
        setselectCo(coordinate);
    
        // Check if any filters are active
        const isFilterActive = searchVehicle || searchDriver || searchType || searchLocation || searchMaterial || searchOrganization || searchPass;
    
        if (!isFilterActive) {
            // Find the index of the waste item with the selected coordinate
            const index = wastes.findIndex(waste => areCoordinatesEqual(coordinate, waste.coords));
    
            if (index !== -1) {
                // Calculate the page number
                const pageNumber = Math.ceil((index + 1) / itemsPerPage);
                setCurrentPage(pageNumber);
            }
        }
    };
    

    const totalPages = Math.ceil(filterData.length / itemsPerPage);

    const renderPagination = () => {
        let startPage, endPage;
        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (currentPage + 2 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage + 2;
            }
        }

        const pages = Array.from({ length: (endPage + 1) - startPage }).map((_, idx) => startPage + idx);

        return (
            <div className="pagination">
                <button
                    onClick={() => setCurrentPage(1)}
                    disabled={currentPage === 1}
                    style={paginationButtonStyle(currentPage === 1)}
                >
                    Αρχική
                </button>
                <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    style={paginationButtonStyle(currentPage === 1)}
                >
                    Προηγούμενη
                </button>
                {currentPage > 3 && (
                    <>
                        <button
                            onClick={() => setCurrentPage(1)}
                            style={paginationButtonStyle(false)}
                        >
                            1
                        </button>
                        <span>...</span>
                    </>
                )}
                {pages.map(page =>
                    <button
                        key={page}
                        onClick={() => setCurrentPage(page)}
                        style={paginationButtonStyle(page === currentPage)}
                    >
                        {page}
                    </button>
                )}
                {currentPage < (totalPages - 2) && (
                    <>
                        <span>...</span>
                        <button
                            onClick={() => setCurrentPage(totalPages)}
                            style={paginationButtonStyle(false)}
                        >
                            {totalPages}
                        </button>
                    </>
                )}
                <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    style={paginationButtonStyle(currentPage === totalPages)}
                >
                    Επόμενη
                </button>
                <button
                    onClick={() => setCurrentPage(totalPages)}
                    disabled={currentPage === totalPages}
                    style={paginationButtonStyle(currentPage === totalPages)}
                >
                    Τελευταία
                </button>
                <select
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(Number(e.target.value))}
                    style={{ margin: '10px', padding: '5px', margin: '5px', border: '1px solid black' }}
                >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>
        );
    };

    const paginationButtonStyle = (isActive) => ({
        padding: '5px',
        margin: '5px',
        border: '1px solid black',
        background: isActive ? '#4CAF50' : 'white',
        color: isActive ? 'white' : 'black',
        cursor: 'pointer',
        fontFamily: 'Arial, sans-serif'
    });


    return (
        <div>
            <NavBar />


            <div className='container3'>
                <div className='main-top' style={{ width: '100vw' }}>
                    <h3>Ροές Απορριμμάτων</h3>
                </div>
                <div className='add_btn mt-2 mb-2'></div>
                <section className='main'>

                    <div className="main-content">
                        <table  className='table' id='EmployeeTable'>
                            <thead>
                                <tr>
                                    <th>Α/Α</th>
                                    <th>Blockchain id</th>
                                    <th>Ημέρα</th>
                                    <th>Ώρα</th>
                                    <th>Όχημα
                                        <br />
                                        <input
                                            style={{ width: "100px" }}
                                            type="text"
                                            placeholder="Αναζήτηση"
                                            value={searchVehicle}

                                            onChange={(e) => {
                                                setsearchConstant('vehicle')
                                                setsearchVehicle(e.target.value);
                                                setCurrentPage(1);
                                            }}
                                        />
                                    </th>
                                    <th>
                                        Οδηγός
                                        <br />
                                        <input
                                            style={{ width: "100px" }}
                                            type="text"
                                            placeholder="Αναζήτηση"
                                            value={searchDriver}
                                            onChange={(e) => {
                                                setsearchConstant('driver')
                                                setsearchDriver(e.target.value);
                                                setCurrentPage(1);
                                            }}
                                        />
                                    </th>
                                    <th>Θέση Φόρτωσης
                                        <br />
                                        <input
                                            style={{ width: "100px" }}
                                            type="text"
                                            placeholder="Αναζήτηση"
                                            value={searchType}
                                            onChange={(e) => {
                                                setsearchConstant('type')
                                                setsearchType(e.target.value);
                                                setCurrentPage(1);
                                            }}
                                        /></th>

                                    <th>Χώρος Απόρριψης
                                        <br />
                                        <input
                                            style={{ width: "100px" }}
                                            type="text"
                                            placeholder="Αναζήτηση"
                                            value={searchLocation}
                                            onChange={(e) => {

                                                setsearchConstant('location')
                                                setsearchLocation(e.target.value);
                                                setCurrentPage(1);
                                            }}
                                        />
                                    </th>

                                    <th>
                                        Θέση GPS</th>
                                    <th>
                                        Βάρος(kg)
                                    </th>
                                    <th>  Υλικό
                                        <br />
                                        <input

                                            style={{ width: "100px" }}
                                            type="text"
                                            placeholder="Αναζήτηση "
                                            value={searchMaterial}
                                            onChange={(e) => {

                                                setsearchConstant('material')
                                                setsearchMaterial(e.target.value);
                                                setCurrentPage(1);
                                            }}
                                        />

                                    </th>
                                    <th>
                                        Φορέας
                                        <br />
                                        <input
                                            style={{ width: "100px" }}
                                            type="text"
                                            placeholder="Αναζήτηση"
                                            value={searchOrganization}
                                            onChange={(e) => {

                                                setsearchConstant('organization')
                                                setsearchOrganization(e.target.value);
                                                setCurrentPage(1);
                                            }}
                                        />

                                    </th>
                                    <th>
                                        Παρατηρήσεις
                                        <br />
                                        <input
                                            style={{ width: "100px" }}
                                            type="text"
                                            placeholder="Αναζήτηση "
                                            value={searchPass}
                                            onChange={(e) => {

                                                setsearchConstant('pass')
                                                setsearchPass(e.target.value);
                                                setCurrentPage(1);
                                            }}
                                        />
                                    </th>

                                    {/*<th>Επιλογές</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((waste, index) => (
                                    <tr key={index} >
                                        <th scope='row'>{index + 1}</th>
                                        <td>
                                            <span
                                                className="trim-text"
                                                style={{ cursor: 'pointer', fontWeight: 'bold', color: waste.co === 1 ? 'red' : '' }}
                                                onClick={() => navigate('/wasteDetails/' + waste._id, { state: { co: waste.co } })}
                                            >
                                                {waste.hash?.length > 15 ? `${waste.hash?.slice(0, 15)}...` : waste.hash}
                                            </span>

                                        </td>
                                        <td>{waste.date}</td>
                                        <td>{waste.time}</td>
                                        <td>{waste.vehicle}</td>
                                        <td>{waste.driver}</td>
                                        <td>{waste.type}</td>
                                        <td>{waste.location}</td>
                                        <td
                                            onClick={() => handleCoordinatesChange(waste.coords)}
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: selectCo && areCoordinatesEqual(selectCo, waste.coords) ? 'lightblue' : 'transparent',
                                                textDecoration: 'underline'
                                            }}
                                            >
                                            {waste.coords}</td>

                                        <td>{waste.kg}</td>
                                        <td>{waste.material}</td>
                                        <td>{waste.organisation}</td>
                                        <td>{waste.pass}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                
                        {renderPagination()}

                    </div>


                    
                        <>
                            <GreenButton style={{
                                textTransform: 'none', marginTop: '20px'
                            }} href='/add-waste'>
                                Προσθήκη
                            </GreenButton>
                        </>
                   

                    <GreenButton style={{
                        textTransform: 'none', margin: '20px 10px 0 10px'
                    }} onClick={createAndDownloadWastePDF}>
                        Εκτύπωση
                    </GreenButton>
                    <GreenButton style={{
                        textTransform: 'none', marginTop: '20px'
                    }} onClick={handleDownloadExcel}>
                        Εξαγωγή
                    </GreenButton>

                    <GreenButton style={{
                        textTransform: 'none', margin: '20px 10px 0 10px'
                    }} onClick={handleResetMap}>
                        Reset Map
                    </GreenButton>




                    <DateRangePicker onChange={handleSelect}
                        style={{ marginLeft: '10px', border: '1px solid black', borderRadius: '5px', marginTop: '20px' }}
                        placeholder='Απο Ημ/νια - Εως Ημ/νια' format="dd-MM-yyyy"
                        ranges={[

                            {
                                label: 'Σήμερα',
                                value: [new Date(), new Date()]
                            },
                            {
                                label: 'Εχτές',
                                value: [
                                    new Date(new Date().setDate(new Date().getDate() - 1)),
                                    new Date(new Date().setDate(new Date().getDate() - 1)),
                                ]
                            },
                            {
                                label: '1 εβδομάδα',
                                value: [
                                    new Date(new Date().setDate(new Date().getDate() - 6)),
                                    new Date(new Date())
                                ]
                            },
                            {
                                label: '1 μήνας',
                                value: [
                                    new Date(new Date().setDate(1)),
                                    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                                ]
                            }


                        ]}
                    />

                </section>
                <br />
                <WasteMap
                    key={mapKey}
   
                    coordinates={selectedCoordinates}
                    coordinate={selectCo}
                    data={wastes}
                    resetMap={resetMap}
                    onResetDone={() => setResetMap(false)}
                    onSelectItem={handleSelectItem}
                    selectedItem={selectedItem}
                    onMarkerClick={handleMapMarkerClick}
                    handleMarkerSelect={handleMarkerSelect}
                    onOverlayClose={() => setselectCo(null)}
                />
            </div>
        </div>


    )
}

export default WasteList; 